.NFTCard {
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 16px;
  transform: scale(0.9);
}

.CategoryCard {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.OverlayImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}
.ThumbImgStyle {
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin-left: 2px;
  border-radius: 50%;
}
.CoverImgStyle {
  top: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.CategoryImage {
  min-height: 100px !important;
  min-width: 100px !important;
  border-radius: 50%;
  overflow: hidden;
}

.bn-onboard-custom.bn-onboard-modal {
  z-index: 199;
}
.blogContent_style p {
  background-color: red;
}
